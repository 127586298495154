<template>
  <div class="animated fadeIn ">
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1 class="h3">{{ $t('message.change_password') }}</h1>
          </div>
          <form @submit.prevent="submit">
        <b-row>
          <b-col sm="12">
              <validation-field :label="$t('message.old_password')"
                                :v="$v.form.passwordOld">
                <input class="form-control" id="passwordOld"
                       maxlength="50"
                       type="password"
                       v-model="form.passwordOld"
                       v-on:blur="$v.form.passwordOld.$touch()"
                />
              </validation-field>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <validation-field :label="$t('message.new_password')"
                              :v="$v.form.passwordNew">
              <input class="form-control" id="passwordNew"
                     maxlength="50"
                     type="password"
                     v-model="form.passwordNew"
                     v-on:keydown="$v.form.passwordNew.$touch()"
              />
            </validation-field>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <validation-field :label="$t('message.password_repeat')"
                              :v="$v.form.passwordConfirm">
              <input class="form-control" id="passwordConfirm"
                     maxlength="50"
                     type="password"
                     v-model="form.passwordConfirm"
                     v-on:blur="$v.form.passwordConfirm.$touch()"
              />
            </validation-field>
          </b-col>
        </b-row>
        <hr class="mb-4">
        <b-row>
          <b-col sm="12" md="6" xl="6">
            <b-button :class="{invalid:$v.form.$invalid}" block
                      type="submit" class="bg-theme-secondary">
              {{$t('message.button_send_text')}}
            </b-button>
          </b-col>
          <b-col sm="12" md="6" xl="6">
            <b-button block type="button" v-on:click="reset()"
                      variant="secondary">
              {{$t('message.button_reset_text')}}
            </b-button>
          </b-col>
        </b-row>
      </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ValidationField from "../../components/ui/ValidationField";
  import ApiResolver from '../../components/common/ApiResolver';
  import Api from '../../components/common/Api';
  import {helpers, required, sameAs, minLength} from 'vuelidate/lib/validators'


  const atLeastOneUppercaseLetter = helpers.regex('alpha', /[A-Z]/);
  const atLeastOneLowercaseLetter = helpers.regex('alpha', /[a-z]/);
  const atLeastOneNumber = helpers.regex('alpha', /[0-9]/);
  const atLeastOneSymbol = helpers.regex('alpha', /(?=.*?[#?!@$%&*_~])/);
  const notSuchSymbols = helpers.regex('alpha', /^[a-zA-Z0-9#?!@$%&*_~]*$/);


  export default {
    name: "ChangePassword",
    components: {
      ValidationField
    },
    data() {
      return {
        form: {
          passwordOld: null,
          passwordNew: null,
          passwordConfirm: null
        },
        error_message: null
      }
    },
    computed: {},
    methods: {
      submit() {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) return;

        Api
          .post(ApiResolver.get('CHANGE_PASSWORD'), JSON.stringify(this.form))
          .then(response => {

            this.$store.dispatch('alerts/setAlert', {
              type: 'success',
              text: this.$t('message.change_password_successfull')
            });

            /** redirect alla login **/

            window.location.href = '/login';
          })
          .catch(reason => {

            this.error_message = this.$t('message.change_password_error')

            this.$store.dispatch('alerts/setAlert', {
              type: 'danger',
              text: this.error_message
            });

          });
      },
      reset() {

        this.form = {
          passwordOld: null,
          passwordNew: null,
          passwordConfirm: null
        };
        this.$v.form.reset();
      }
    },
    validations: {
      form: {
        passwordOld: {
          required: required,
        },
        passwordNew: {
          required: required,
          atLeastOneUppercaseLetter,
          atLeastOneLowercaseLetter,
          atLeastOneNumber,
          min: minLength(6),
          atLeastOneSymbol,
          notSuchSymbols
        },
        passwordConfirm: {
          required: required,
          sameAs: sameAs('passwordNew')
        }
      }

    },
  }
</script>

<style scoped>
  .hasError label {
    color: red;
  }

  .error {
    color: red
  }
</style>
